/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable import/exports-last */
import type { FlightTimeLineDataModel, TaCDataMOdel } from '../hasil-pencarian/types';

export interface AgentProfileDataModel {
  address: string
  company_name: string
  name: string
  phone: string
}

interface PaymentDetailsDataModel {
  amount: number
  description: string
  paymentLimit: string
}

export interface CreateCartDataModel {
  cartId: string
}

export interface PaymentInstructionDataModel {
  ATM: string[]
  Mobile_Banking: string[]
}

export interface OrderDataModel {
  account_no: string
  timelimit_booking_fee: string
}

export interface BookingFeeDataModel {
  bookingFee: number
}

export interface PembayaranModel {
  paymentDetails: PaymentDetailsDataModel[]
  resultDetail: FlightTimeLineDataModel[]
  termsAndCondition: TaCDataMOdel
  createCart: CreateCartDataModel
  agentProfile: AgentProfileDataModel
  orderDetails: FlightTimeLineDataModel
  paymentInstruction: PaymentInstructionDataModel
  order: OrderDataModel
  booking: BookingFeeDataModel
}

export enum PembayaranActionType {
  PostPaymentDetailsList = '⌘➝App➝Pembayaran➝GetPaymentDetailsList',
  GetResultDetailList = '⌘➝App➝Pembayaran➝GetResultDetailList',
  GetRincianPembayaranList = '⌘➝App➝Pembayaran➝GetRincianPembayaran',
  GetTACbyProductID = '⌘➝App➝Pembayaran➝GetTACbyProductID',
  PostCreateCart = '⌘➝App➝Pembayaran➝PostCreateCart',
  GetAgentProfile = '⌘➝App➝Pembayaran➝GetAgentProfile',
  GetOrderDetails = '⌘➝App➝Pembayaran➝GetOrderDetails',
  GetPaymentInstruction = '⌘➝App➝Pembayaran➝GetPaymentInstruction',
  PostOrder = '⌘➝App➝Pembayaran➝PostOrder',
  GetBookingFee = '⌘➝App➝Pembayaran➝GetBookingFee'
}

export type PembayaranAction = {
  data: AgentProfileDataModel
  type: PembayaranActionType.GetAgentProfile
} | {
  data: BookingFeeDataModel
  type: PembayaranActionType.GetBookingFee
} | {
  data: CreateCartDataModel
  type: PembayaranActionType.PostCreateCart
} | {
  data: FlightTimeLineDataModel
  type: PembayaranActionType.GetOrderDetails
} | {
  data: FlightTimeLineDataModel[]
  type: PembayaranActionType.GetResultDetailList
} | {
  data: OrderDataModel
  type: PembayaranActionType.PostOrder
} | {
  data: PaymentDetailsDataModel[]
  type: PembayaranActionType.PostPaymentDetailsList
} | {
  data: PaymentInstructionDataModel
  type: PembayaranActionType.GetPaymentInstruction
} | {
  data: TaCDataMOdel
  type: PembayaranActionType.GetTACbyProductID
};
